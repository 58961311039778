import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// redux toolkit queries and mutations
export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://super-admin.obilash.com/api",
  }),
  endpoints: (build) => ({
    //  all query and mutation
    getGeneralData: build.query({
      query: () => "/general-data",
    }),
    getSliders: build.query({
      query: () => "/home-sliders",
    }),
    getAllProduct: build.query({
      query: () => `/products`,
    }),
    getSpecificProductById: build.query({
      query: (id) => `/product/details/${id}`,
    }),
    getAllCategories: build.query({
      query: () => "/categories",
    }),
    getCategoryProducts: build.query({
      query: (cat_id) => `/category-products/${cat_id}`,
    }),
    getSubCategoriesProducts: build.query({
      query: (id) => `/subcategory-products/${id}`,
    }),
    getTotalCart: build.query({
      query: (ip) => `/cart-products-count/${ip}`,
    }),
    getCartProducts: build.query({
      query: (ip) => `/get-cart-products/${ip}`,
    }),
    searchByName: build.query({
      query: (name) => `/product-search/${name}`,
    }),
    OffersList: build.query({
      query: () => "/offers",
    }),
    offersProducts: build.query({
      query: (slug) => `/offer-product/${slug}`,
    }),
    aboutUs: build.query({
      query: () => "/about-us",
    }),
    privacy: build.query({
      query: () => "/privacy-policy",
    }),
    payment: build.query({
      query: () => "/payment-policy",
    }),
    refund: build.query({
      query: () => "/refund-policy",
    }),
    termsCondition: build.query({
      query: () => "/terms-conditions",
    }),
    offerTime: build.query({
      query: () => "/offer-time",
    }),
    orderDetails: build.query({
      query: (orderId) => `order-details/${orderId}`,
    }),

    // POST, PUT, DElETE, UPDATE mutation
    addToCart: build.mutation({
      query: ({ id, payload }) => ({
        url: `/product/add-to-cart/${id}`,
        method: "POST",
        body: payload,
      }),
    }),
    placeOrder: build.mutation({
      query: (order) => ({
        url: `/confirm-order`,
        method: "POST",
        body: order,
      }),
    }),
    cartDelete: build.mutation({
      query: (id) => ({
        url: `/delete-cart/${id}`,
        method: "GET",
      }),
    }),
    contactUs: build.mutation({
      query: (data) => ({
        url: "/contact-store",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetGeneralDataQuery,
  useGetSlidersQuery,
  useGetAllProductQuery,
  useGetSpecificProductByIdQuery,
  useGetAllCategoriesQuery,
  useGetCategoryProductsQuery,
  useGetSubCategoriesProductsQuery,
  useAddToCartMutation,
  usePlaceOrderMutation,
  useGetTotalCartQuery,
  useGetCartProductsQuery,
  useCartDeleteMutation,
  useSearchByNameQuery,
  useOffersListQuery,
  useOffersProductsQuery,
  usePaymentQuery,
  usePrivacyQuery,
  useRefundQuery,
  useTermsConditionQuery,
  useAboutUsQuery,
  useContactUsMutation,
  useOfferTimeQuery,
  useOrderDetailsQuery,
} = baseApi;
